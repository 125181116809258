<template>
    <v-app style="background:#EFEFEF" v-if="datosLogin.rol.name != 'root' && datosLogin.rol.name != 'admin'">
    <v-container grid-list-xl style="height:100vh; display: flex; align-items: center; justify-content: center;" @click="alterna_modo_de_pantalla((datosLogin.rol.name != 'root' && datosLogin.rol.name != 'admin') ?true:false)">
        <transition class="checador" name="slide" mode="out-in">
            
            <v-flex  id="cardCam">
                <div id="wrapper" >
                    <div id="dialog">

                        <template v-if="opcion == null">
                            <div class="titulo-opciones">
                                <h3>
                                    Elige una opción para registrar tu entrada o salida:
                                </h3>
                            </div>

                            <div class="opciones">
                                <v-btn class="btn-opcion-web mb-6" @click="elegirOpcion('web')">
                                    Checador web
                                </v-btn>
                                <v-btn class="btn-opcion-codigo" @click="elegirOpcion('codigo')">
                                    Ingresar código de empleado
                                </v-btn>
                            </div>
                        </template>

                        <template v-else-if="opcion == 'web'">
                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" style="display:flex;">
                                <v-icon color='#2DB9D1' size="25" class="mt-1">mdi-camera-outline</v-icon>
                                <h3 class="tituloChecador">Checador webcam</h3>
                            </v-col>

                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="d-flex my-5" style="cursor:pointer" @click="elegirOpcion(null)">
                                <v-icon color='#000000b3l' size="25" class="mt-1" v-text="'$arrow_back_round'"></v-icon>
                                <p class="btn-regresar">Regresar al menú principal</p>
                            </v-col>

                            <template v-if="!isSleep">
                                <v-row class="d-flex justify-center ma-0">
                                    <div>
                                        <v-autocomplete
                                            :items="devices"
                                            item-text="label"
                                            item-value="deviceId"
                                            class="py-0 my-0"
                                            label="Webcam"
                                            hide-details
                                            v-model="camera"
                                            outlined
                                            dense
                                            prepend-inner-icon="mdi-camera-outline"
                                            style="width:470px"
                                        >
                                        </v-autocomplete>
                                    </div>
                                </v-row>

                                <template v-if="img == null">
                                    <div class="center el-video text-center justify-center" style="height: 400px; display: flex; align-items: center;">
                                        <web-cam
                                            id="video"
                                            ref="webcam"
                                            height="350"
                                            width="500"
                                            class="webcam"
                                            :device-id="deviceId"
                                            @cameras="onCameras"
                                            @camera-change="onCameraChange"
                                        />
                                    </div>
                                </template>

                                <template v-else>
                                    <v-flex class="center" style="position: relative; height: 400px; display: flex; align-items: center; padding: 0px 80px;">
                                        <img
                                            :src="img"
                                            class="webcam"
                                            style="opacity: 0.6;"
                                        />
                                    </v-flex>

                                    <v-layout wrap>
                                        <v-flex xs12 sm4 md4 />
                                            <v-flex xs12 sm4 md4 class="center">
                                                <template v-if="!isProcessFinished">
                                                    <h3 class="tituloCodigo">Validando registro</h3>
                                                    <beat-loader
                                                        :loading="true"
                                                        :color="'#15D411'"
                                                        :margin="'2px'"
                                                        :radius="'100px'"
                                                    ></beat-loader>
                                                </template>

                                                <template v-else>
                                                    <h3 class="tituloCodigo">Habilitando cámara en ...{{ countDown }}</h3>
                                                </template>
                                            </v-flex>
                                        <v-flex xs12 sm2 md4 />
                                    </v-layout>
                                </template>
                            </template>

                            

                            <template v-else>
                                <v-row class="ma-0 mt-5 d-flex justify-center">
                                    <v-btn class="btnCapturar" @click="resetSleep()" >Reactivar cámara</v-btn>
                                </v-row>
                            </template>

                        </template>


                        <template v-else-if="opcion == 'codigo'">
                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" style="display:flex;">
                                <h3 class="tituloChecador">Registrar código de empleado</h3>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="d-flex my-5" style="cursor:pointer" @click="elegirOpcion(null)">
                                <v-icon color='#000000b3l' size="25" class="mt-1" v-text="'$arrow_back_round'"></v-icon>
                                <p class="btn-regresar">Regresar al menú principal</p>
                            </v-col>
                            <div class="title-otp my-3">
                                Ingresa clave de empleado
                            </div>
                            <div>
                                <div class="ma-auto position-relative" style="max-width: 470px; max-height:80px"  >
                                    <v-otp-input
                                        v-model="otp"
                                        :disabled="loading"
                                        length="4"
                                        class="otp"
                                        type="number"
                                    ></v-otp-input>
                                    
                                    <v-overlay :absolute="true" :value="loading" class="py-13">
                                        <v-progress-circular
                                            indeterminate
                                            color="primary"
                                            
                                        ></v-progress-circular>
                                    </v-overlay>
                                </div>
                            </div>

                            <div class="title-otp mb-3 mt-15">
                                Ingresa tu clave de seguridad
                            </div>
                            <div>
                                <div class="ma-auto position-relative" style="max-width: 470px; max-height:80px"  >
                                    <v-otp-input
                                        v-model="codigo_otp"
                                        :disabled="loading"
                                        length="4"
                                        class="otp otp-texto"
                                    ></v-otp-input>
                                    
                                    <v-overlay :absolute="true" :value="loading" class="py-13">
                                        <v-progress-circular
                                            indeterminate
                                            color="primary"
                                            
                                        ></v-progress-circular>
                                    </v-overlay>
                                </div>
                            </div>

                            <div class="verificar-codigo">
                                <v-btn class="btn-verificar mb-6" @click="checarClave()" :disabled="loading">
                                    Verificar código
                                </v-btn>
                            </div>
                        </template>
                    </div>
                </div>
            </v-flex>
        </transition>

        <template v-if="primer_login">
            <v-layout row justify-center>
                <v-dialog v-model="dialog_primer_login" persistent max-width="500px">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>

                    <v-card>
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-title class="card_titulo padding_izquierdo padding_derecho pb-5">
                                <span class="titleModal" v-text="titulo_modal"></span>
                            </v-card-title>

                            <v-card-text class="card_texto">
                                <v-container grid-list-md key="unique" class="pa-0">
                                    <span id="span_cambio">
                                        Por políticas de seguridad, es requerido cambiar la contraseña.
                                    </span>

                                    <v-row style="padding-top: 32px;">
                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Contraseña" rules="required|verify_password">
                                                <v-text-field
                                                    outlined
                                                    label="Nueva contraseña"
                                                    v-model="nuevo_password"
                                                    id="nuevo_password"
                                                    auto
                                                    required
                                                    :error-messages="errors"
                                                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                                    @click:append="show1 = !show1"
                                                    :type="show1 ? 'text' : 'password'"
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Confirmación" rules="required|verify_password">
                                                <v-text-field
                                                    outlined
                                                    label="Confirmar la contraseña"
                                                    v-model="confirmacion"
                                                    id="verificar_password"
                                                    auto
                                                    required
                                                    :error-messages="errors" 
                                                    @keyup.enter="verificar('confirmacion')"
                                                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                                                    @click:append="show2 = !show2"
                                                    :type="show2 ? 'text' : 'password'"
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <template v-if="password_igual">
                                            <div
                                                class="v-stepper__label"
                                                style="color:red; padding-left: 3%; margin-top: -5%;"
                                            >
                                                Las contraseñas no son iguales, favor de verificar.
                                            </div>
                                        </template>
                                    </v-row>
                                </v-container>
                            </v-card-text>

                            <v-card-actions class="d-flex justify-end padding_abajo padding_izquierdo padding_derecho">
                                
                                <v-btn class="modal-button" @click="verificar()">
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </v-layout>
        </template>
    </v-container>
    </v-app>
</template>

<script>
import registrosApi from "../api/registros";
import empleadosApi from "../api/empleados";
import AzureCognitiveService from "../api/cognitiveServices";
import { mapActions, mapState } from "vuex";
import axios from "axios";
import urlImg from "../plugins/Urlimagen";
import Notify from "../plugins/notify";
import env from "../plugins/enviroment";
import { WebCam } from "vue-web-cam";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import userApi from "../api/usuarios";
import * as faceapi from 'face-api.js';
import BeatLoader from "vue-spinner/src/BeatLoader.vue";

export default {
    components: {
        "web-cam": WebCam,
        PulseLoader,
        BeatLoader
    },
    data() {
        return {
            userData: null,
            employeeData: null,

            urlAvatar: urlImg.getURL(),
            date: null,
            time: null,

            isFaceDetected: false,
            isProcessFinished : false,
            countDown : 5,

            img: null,
            camera: null,
            deviceId: null,
            devices: [],

            photoURL: null,

            checkerReady : false,

            coordenadas: {
                lat: null,
                lng: null,
            },
            primer_login: false,
            titulo_modal: "Cambio de contraseña",
            dialog_primer_login: false,
            nuevo_password: "",
            confirmacion: "",
            password_igual: false,

            user_vuex: {
                email: "",
                password: "",
            },

            usuario: {
                id: "",
                password: "",
                primer_inicio_sesion: "",
            },
            show1: false,
            show2: false,
            datosLogin: "",

            sleepCounter: 0,
            isSleep: false,


            itemsTest:["uno","dos","tres"],
            itemTest: null,
            

            loading: false,
            otp: '',
            codigo_otp:'',
            
            opcion: null,
        }
    },
    computed: {
        ...mapState("auth", ["datos", "user"]),
        device: function() {
            return this.devices.find((n) => n.deviceId === this.deviceId);
        },
    },
    watch: {
        camera: function(id) {
            this.deviceId = id;
        },
        devices: function() {
            const [first, ...tail] = this.devices;
            if (first) {
                this.camera = first.deviceId;
                this.deviceId = first.deviceId;
            }
        },
    },
    methods: {
        ...mapActions("auth", ["logout", "update_password", "update_cliente"]),
        init() {
            let objDate = new Date();
            let month = String(objDate.getMonth() + 1);
            month = month.length < 2 ? "0" + month : month;

            let day = String(objDate.getDate());
            day = day.length < 2 ? "0" + day : day;
            this.date = objDate.getFullYear() + "-" + month + "-" + day;
            $("#primero").focus();
        },
        alterna_modo_de_pantalla(bool) {
            if(bool){
                if (document.documentElement.requestFullScreen) {
                    document.documentElement.requestFullScreen();
                } else if (document.documentElement.mozRequestFullScreen) {
                 document.documentElement.mozRequestFullScreen();
                } else if (document.documentElement.webkitRequestFullScreen) {
                    document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
                }
            }else{
                if (document.cancelFullScreen) {
                    document.cancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                } 
            }
        },
        primerInicio() {
            if (this.datos.primer_inicio_sesion == true) {
                this.isSleep = true;
                this.primer_login = true;
                this.dialog_primer_login = true;
                setTimeout(function() {
                    document.getElementById("nuevo_password").focus();
                }, 250);
            }
        },
        async update() {
            this.llena_datos();
            await this.update_password(this.user_vuex);
            await userApi.updatePassword(this.usuario).then((response) => {
                userApi.getUsuario(this.usuario.id).then((response) => {
                    this.update_cliente(response);
                })
                .catch((err) => {
                    console.error(err);
                });
                this.cerrarModal();
                Notify.Success(
                    "Operación exitosa",
                    "Tu contraseña ha sido actualizada satisfactoriamente."
                );
            })
            .catch((err) => {
                console.error(err.response.data.error);
                Notify.Error("Error!", err.response.data.error);
            });
        },
        verificar() {
            this.$refs.form.validate().then(success => {
                if (success) {
                    if (this.confirmacion == this.nuevo_password) {
                        this.password_igual = false;
                        this.update();
                    } else {
                        this.password_igual = true;
                    }
                }
            });
        },
        llena_datos() {
            this.usuario.id = this.datos.id;
            this.usuario.primer_inicio_sesion = false;
            this.usuario.password = this.confirmacion;
            this.user_vuex.email = this.usuario.email;
            this.user_vuex.password = this.confirmacion;
        },
        cerrarModal() {
            this.dialog_primer_login = false;
            this.primer_login = false;
            this.usuario = {};
        },
        resetvalue() {
            this.img            = null;
            this.employeeData   = null;
            this.isFaceDetected = false;
            this.countDown      = 5;
            this.isProcessFinished = false;
        },
        resetSleep() {
            this.isSleep = false;
            setTimeout(() => {
                this.$refs.webcam.start();
                this.resetFaceDetection();
            }, 1500);
        },
        resetFaceDetection() {
            $("canvas").remove();
            this.sleepCounter = 0;
            const video = document.getElementById('video');

            let sleepCounter = setInterval(() => this.sleepCounter = this.sleepCounter + 1, 1000);

            video.addEventListener('playing', () => {
                const displaySize     = { width: video.width, height: video.height };
                const canvas          = faceapi.createCanvasFromMedia(video);
                canvas.style.position = "absolute";
                canvas.width          = "500px";
                canvas.height         = "350px";
                $('.el-video').append(canvas);
                faceapi.matchDimensions(canvas, displaySize);

                setInterval(async () => {
                    const detections = await faceapi.detectAllFaces(video, new faceapi.TinyFaceDetectorOptions())
                    const resizedDetections = faceapi.resizeResults(detections, displaySize)
                    canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height)
                    faceapi.draw.drawDetections(canvas, resizedDetections)

                    if(detections.length == 1 && !this.isFaceDetected) {
                        clearInterval(sleepCounter);
                        this.isFaceDetected = true;
                        this.onCapture();
                    }

                    if(this.sleepCounter >= 60) {
                        this.isSleep = true;
                    }
                }, 1000);
            });
        },
        async verifyEmployee(id) {
            await empleadosApi.getEmpleado(id).then(async (response) => {
                if(response != null) {
                    this.employeeData = response;
                } else {
                    this.resetvalue()
                    setTimeout(() => {
                        this.$refs.webcam.start();
                        this.resetFaceDetection();
                    }, 1500);
                    Notify.ErrorToast("No se encontró al empleado en el sistema.");
                }
                })
                .catch((error) => {
                    console.error(error);
                    this.resetvalue()
                setTimeout(() => {
                    this.$refs.webcam.start();
                    this.resetFaceDetection();
                }, 1500);
                    Notify.ErrorToast("Al parecer ocurrió un error al consultar la clave del empleado.");
                });
        },
        onCameras(cameras) {
            this.devices = cameras;
        },
        onCameraChange(deviceId) {
            this.deviceId = deviceId;
            this.camera = deviceId;
        },
        onCapture() {
            this.img = this.$refs.webcam.capture();

            this.startProcess();
        },
        async startProcess() {
            //await this.uploadImage();
            //await this.uploadImageCliker();
            //await this.faceDetection();
            this.detectionAWS()
        },
        b64toBlob(base64URL, sliceSize) {
            var arr = base64URL.split(",");
            var contentType = arr[0].match(/:(.*?);/)[1];
            var b64Data = arr[1];
            sliceSize = sliceSize || 512;

            var byteCharacters = atob(b64Data);
            var byteArrays = [];

            for (
                var offset = 0;
                offset < byteCharacters.length;
                offset += sliceSize
            ) {
                var slice = byteCharacters.slice(offset, offset + sliceSize);

                var byteNumbers = new Array(slice.length);
                for (var i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                var byteArray = new Uint8Array(byteNumbers);

                byteArrays.push(byteArray);
            }

            var blob = new Blob(byteArrays, { type: contentType });
            return blob;
        },
        async uploadImage() {
            const formdata = new FormData();
            formdata.append("image", this.b64toBlob(this.img));

            delete axios.defaults.headers.common["X-Requested-With"];

            await axios({
                method: "POST",
                url: env.IMGUR_ENDPOINT,
                data: formdata,
                headers: {
                Accept: "application/json",
                Authorization: "Client-ID " + env.IMGUR_CLIENT_ID,
                },
            })
            .then((response) => {
                this.photoURL = response.data.data.link;
            })
            .catch((error) => {
                this.resetvalue()
                setTimeout(() => {
                    this.$refs.webcam.start();
                    this.resetFaceDetection();
                }, 1500);
                console.error(error);
            });
        },
        async uploadImageCliker(){
            const formdata = new FormData();
            formdata.append("foto", this.b64toBlob(this.img));
            //formdata.append("foto", this.img)
            console.log(this.img)

            await registrosApi.uploadImageCliker(formdata).then((response) => {
                this.photoURL = this.urlAvatar + response.url;
            })
            .catch((err) => {
                this.resetvalue()
                setTimeout(() => {
                    this.$refs.webcam.start();
                    this.resetFaceDetection();
                }, 1500);
                console.error(err);
                if(typeof err.response.data.error === 'string'){
                    console.log(err.response.data.error)
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }
                    console.log(leyenda)
                }
            });

        },
        async faceDetection() {
            let userDataFace = null;
            await AzureCognitiveService.detect({ url: this.photoURL }).then((facedetect_res) => {
                if(facedetect_res.length > 0) {
                    let face_id = facedetect_res[0].faceId;
                    let objSimilars = {
                        faceId: face_id,
                        largeFaceListId: this.datosLogin.empresa_id,
                        maxNumOfCandidatesReturned: 2,
                    };

                    AzureCognitiveService.findSimilars(objSimilars).then((similarfaces_res) => {
                        if (similarfaces_res.length > 0) {
                            var similar_face = similarfaces_res[0];

                            AzureCognitiveService.getFaces(this.datosLogin.empresa_id).then((facelist_res) => {
                                let validated = false;

                                for (let i = 0; i < facelist_res.length; i++) {
                                    let face = facelist_res[i];
                                    if(face.persistedFaceId == similar_face.persistedFaceId) {
                                        userDataFace  = face.userData
                                        validated     = true;
                                        break;
                                    }
                                }

                                if(validated) {
                                    this.setAssistance(userDataFace);
                                } else {
                                    this.resetvalue()
                                    setTimeout(() => {
                                        this.$refs.webcam.start();
                                        this.resetFaceDetection();
                                    }, 1500);
                                    Notify.ErrorToast(`No se identifico el usuario, intente de nuevo.`);
                                }
                            })
                            .catch((error) => {
                                Notify.ErrorToast(`No se identifico el usuario, intente de nuevo.`);
                                this.resetvalue()
                                setTimeout(() => {
                                    this.$refs.webcam.start();
                                    this.resetFaceDetection();
                                }, 1500);
                            });
                        } else {
                        this.resetvalue()
                        setTimeout(() => {
                            this.$refs.webcam.start();
                            this.resetFaceDetection();
                        }, 1500);
                        Notify.ErrorToast(`No se identifico el usuario, intente de nuevo.`);
                        }
                    })
                    .catch((error) => {
                        this.resetvalue()
                        setTimeout(() => {
                            this.$refs.webcam.start();
                            this.resetFaceDetection();
                        }, 1500);
                        Notify.ErrorToast("No se identifico el usuario, intente de nuevo.");
                    });
                } else {
                    this.resetvalue()
                    setTimeout(() => {
                        this.$refs.webcam.start();
                        this.resetFaceDetection();
                    }, 1500);
                    Notify.ErrorToast("No se identifico el usuario, intente de nuevo.");
                }
            })
            .catch((error) => {
                this.resetvalue()
                setTimeout(() => {
                    this.$refs.webcam.start();
                    this.resetFaceDetection();
                }, 1500);
                Notify.ErrorToast("Hubo un problema al detectar la imagen, intente nuevamente.");
            });
        },
        async detectionAWS() {
            const formdata = new FormData();
            formdata.append("foto", this.b64toBlob(this.img));
            formdata.append("fecha",this.validateAssistanceDateTime("date"))
            formdata.append("hora",this.validateAssistanceDateTime("time"))
            formdata.append("coordenadas",JSON.stringify(this.coordenadas))
            formdata.append("isMobile", true)
            formdata.append("origen","W")

            await registrosApi
            .asistenciaAWS(formdata)
            .then((response) => {
                Notify.SuccessToast(`Registro de asistencia exitosa \n ${response.empleado}.`);
                this.isProcessFinished  = true;
                this.countDown          = 5;
                this.countDownTimer();

                setTimeout(async() => {
                    await this.resetvalue();
                    this.$refs.webcam.start();
                    this.resetFaceDetection();
                }, 5000)
            })
            .catch((error) => {
                this.isProcessFinished  = true;
                this.countDown          = 5;
                this.countDownTimer();

                setTimeout(async() => {
                    await this.resetvalue();
                    this.$refs.webcam.start();
                    this.resetFaceDetection();
                }, 5000)
                Notify.ErrorToast("No se pudo registrar tu asistencia, vuelve a intentar.");
                //console.error(error);
            });

        },
        async setAssistance(employeeId) {
        await this.verifyEmployee(employeeId);

        let params = {
            empleado_id: this.employeeData.id,
            cliente_id: this.employeeData.cliente_id,
            empresa_id: this.employeeData.empresa_id,
            sucursal_id: this.employeeData.sucursal_id,
            departamento_id: this.employeeData.departamento_id,
            fecha: this.validateAssistanceDateTime("date"),
            hora: this.validateAssistanceDateTime("time"),
            isMobile: true,
            id_asp: 0,
            coordenadas: JSON.stringify(this.coordenadas),
            origen: "W",
        };

        await registrosApi
            .store(params)
            .then((response) => {
                Notify.SuccessToast(`Registro de asistencia exitosa \n ${response.empleado}.`);
                this.isProcessFinished  = true;
                this.countDown          = 5;
                this.countDownTimer();

                setTimeout(async() => {
                    await this.resetvalue();
                    this.$refs.webcam.start();
                    this.resetFaceDetection();
                }, 5000)
            })
            .catch((error) => {
                this.resetvalue()
                Notify.ErrorToast("No se pudo registrar tu asistencia, vuelve a intentar.");
                //console.error(error);
            });
        },
        validateAssistanceDateTime(type = "date") {
            var date = new Date();
            let result = "";

            switch (type) {
                case "date":
                    let year = date.getFullYear();
                    let month =
                        date.getMonth() < 9
                        ? "0" + (date.getMonth() + 1)
                        : date.getMonth() + 1;
                    let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

                    result = year + "-" + month + "-" + day;
                    break;

                case "time":
                    let hour =
                        date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
                    let minute =
                        date.getMinutes() < 10
                        ? "0" + date.getMinutes()
                        : date.getMinutes();
                    let second =
                        date.getSeconds() < 10
                        ? "0" + date.getSeconds()
                        : date.getSeconds();

                    result = hour + ":" + minute + ":" + second;
                    break;
            }

            return result;
        },
        getGeoPosition(position) {
            this.coordenadas.lat = position.coords.latitude;
            this.coordenadas.lng = position.coords.longitude;
        },
        countDownTimer() {
            if(this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1
                    this.countDownTimer()
                }, 1000)
            }
        },
        geoVerification(){
            if('geolocation' in navigator){
                navigator.geolocation.getCurrentPosition(
                    ()=>{
                        if(!this.checkerReady){
                            Notify.SuccessToast("Checador facial activado");
                            this.checkerReady = true;
                        }
                        this.alterna_modo_de_pantalla(true);
                    },
                    ()=>{
                            this.isSleep = true;
                            this.alterna_modo_de_pantalla(false);
                            Notify.Warning("Sin permiso de ubicación","Tu navegador no cuenta con el permiso de ubicación, por lo que no podrás usar esta función del sistema. Activalo en la configuración del navegador.","Reintentar",()=>{
                            //this.logout();
                            
                            this.alterna_modo_de_pantalla(false);
                            
                            location.reload();
                            });
                    }
                );
            }
            else{
                Notify.Warning("Lo sentimos.","Tu navegador no cuenta con geolocalizador, por lo que no podrás usar esta función del sistema.","Aceptar",()=>{
                    this.logout();
                    
                    this.alterna_modo_de_pantalla(false);
                    
                    this.$router.push("/");
                    location.reload();
                });
            }
        },
        checarClave () {
            this.loading = true;

            if(this.otp == '' || this.codigo_otp == ''){
                Notify.ErrorToast("Ingresa tu clave.");
                this.loading = false;
                return;
            }

            let params = {
                cliente_id          : this.userData.cliente_id,
                empresa_id          : this.userData.empresa_id,
                fecha               : this.validateAssistanceDateTime("date"),
                hora                : this.validateAssistanceDateTime("time"),
                coordenadas         : JSON.stringify(this.coordenadas),
                clave_empleado      : this.otp,
                codigo_verificacion : this.codigo_otp.toUpperCase(),
            };

            registrosApi.checarCodigo(params).then((response) => {
                Notify.SuccessToast(`Registro de asistencia exitosa \n ${response.empleado}.`);
                
                this.loading = false
                this.otp='';
                this.codigo_otp='';
                
            })
            .catch((err) => {
                this.loading = false
                this.otp='';
                this.codigo_otp='';
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
                console.error(err);
            });
        },
        elegirOpcion(opt){
            this.opcion = opt;
            if(opt == "web"){
                this.resetSleep()
            }
        }
    },
    created() {
        this.datosLogin = this.$session.get("usuario");
        if (this.datosLogin.rol.name == "root" || this.datosLogin.rol.name == "admin") {
            Notify.ErrorToast("Esta vista no esta disponible para este rol de usuario.");
            this.$router.push('/dashboard');
            return;
        }
        this.isSleep = true;

        if(navigator.geolocation) {
            var options = {
                enableHighAccuracy: true,
                timeout: 10 * 1000 * 1000,
                maximumAge: 0,
            };

            navigator.geolocation.getCurrentPosition(
                this.getGeoPosition,
                () => console.error(err),
                options
            );
        } 
        else {
            alert("No se concedieron permisos de geolocalización para la sucursal.");
        }
    },
    updated () {
       
    },
    async mounted() { 
        if (this.datosLogin.rol.name != "root" && this.datosLogin.rol.name != "admin") {
            this.alterna_modo_de_pantalla(true);
        
            this.userData = this.$session.get("usuario");
            this.employeeData = this.$session.get("empleado");

            /**
             * Face Detection
             */
            const video = document.getElementById('video');

            Promise.all([
                faceapi.nets.tinyFaceDetector.loadFromUri('/static/js/face-detection/models'),
                faceapi.nets.faceLandmark68Net.loadFromUri('/static/js/face-detection/models'),
                faceapi.nets.faceRecognitionNet.loadFromUri('/static/js/face-detection/models')
            ]).then(startVideo)

            function startVideo() {
                navigator.mediaDevices.getUserMedia(
                    { video: {} },
                    stream => video.srcObject = stream,
                    err => console.error(err)
                )
            }

            setTimeout(()=>{  
                let sleepCounter = setInterval(() => this.sleepCounter = this.sleepCounter + 1, 1000);

                video.addEventListener('playing', () => {
                    const displaySize     = { width: video.width, height: video.height };
                    const canvas          = faceapi.createCanvasFromMedia(video);
                    canvas.style.position = "absolute";
                    canvas.width          = "500px";
                    canvas.height         = "350px";
                    $('.el-video').append(canvas);
                    faceapi.matchDimensions(canvas, displaySize);

                    setInterval(async () => {
                        const detections = await faceapi.detectAllFaces(video, new faceapi.TinyFaceDetectorOptions())
                        const resizedDetections = faceapi.resizeResults(detections, displaySize)
                        canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height)
                        faceapi.draw.drawDetections(canvas, resizedDetections)
                    
                        if(detections.length == 1 && !this.isFaceDetected) {
                            clearInterval(sleepCounter);
                            this.isFaceDetected = true;
                            this.onCapture();
                        }

                        if(this.sleepCounter >= 60) {
                            this.isSleep = true;
                        }
                    }, 1000); 
                });

            }, 500);

            
            
            await this.geoVerification();
            await this.primerInicio();
            await this.init();
        }
    },
};
</script>

<style scoped>
    .btnCapturar {
        border-radius: 80px;
        color: white !important;
        height: 40px !important;
        background-color: #1E2245 !important;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        text-transform: none;
    }
    .btnCancelar {
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 16px !important;
        line-height: 18px !important;
        text-align: center !important;
        letter-spacing: 0.2px !important;
        background-color: transparent !important;
        color: #a0a4a8 !important;
        margin-right: 5%;
        margin-top: 1%;
        outline: none;
    }

    .tituloCodigo {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #1E2245;
    }
    .btnClave {
        width: 100px !important;
        height: 100px !important;
        left: 113px;
        top: 195px;
        border-radius: 50px !important;

        background: #ffffff;
        border: 1px solid #eff1fb;
        box-sizing: border-box;
    }

    .estiloLetras {
        font-family: "Montserrat";
        font-weight: 400 !important;
        color: #8a8fb4 !important;
        font-size: 13px !important;
        line-height: 16px;
        margin-top: -2% !important;
        padding-left: 9%;
        padding-right: 55%;
    }
    .invertirColor {
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: rgba(0, 0, 0, 0.54);
    }
    .tamIcono {
        height: 24px !important;
        align-self: center !important;
    }
    .iconoDelgado {
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-fill-color: rgba(0, 0, 0, 0.54);
    }
    .center {
        text-align: center !important;
    }
    .btnGuardar {
        font-family: "Montserrat";
        font-size: 14px !important;
        font-weight: 700 !important;
        background-color: #1E2245 !important;
        border-radius: 23px 23px 23px 23px;
        color: white !important;
        width: 70% !important;
        height: 40px !important;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    #wrapper {
        /* font-family: Lato; */
        font-family: "Montserrat";
        font-size: 1.5rem;
        text-align: center;
        box-sizing: border-box;
        color: #333;
    }
    #wrapper #dialog h3 {
        margin: 0 0 10px;
        padding: 0;
        line-height: 1.25;
    }
    #wrapper #dialog #form {
        /*  max-width: 240px; */
        /* margin: 25px auto 0; */
    }
    /* #wrapper #dialog #form input {
        margin: 0 5px;
        text-align: center;
        line-height: 80px;
        font-size: 50px;
        border: solid 1px #ccc;
        box-shadow: 0 0 2px #ccc inset;
        outline: none;
        width: 20%;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        border-radius: 3px;
        color: #96999a !important;
    }
    #wrapper #dialog #form input:focus {
        border-color: #f0c087;
        box-shadow: 0 0 5px #f0c087 inset;
    }
    #wrapper #dialog #form input::-moz-selection {
        background: transparent;
    }
    #wrapper #dialog #form input::selection {
        background: transparent;
    } */
    #wrapper #dialog div {
        /* position: relative; */
        z-index: 1;
    }

    .slide-enter-active,
    .slide-leave-active {
        transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
    }
    .slide-enter,
    .slide-leave-to {
        opacity: 0;
        transform: translateX(-20px);
    }

    #cambio_pass {
        /* margin-top: -30px; */
    }
    #span_cambio {
        font-family: 'Montserrat';
        font-style: normal;
        font-size: 13px;
        color: red;
        font-weight: bold;
        margin-bottom: 30px;
    }

    .checador {
        margin-top: auto;
        margin-bottom: auto;
    }
    .tituloChecador {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
        color: #1E2245;
        margin-top: 8px !important;
        margin-bottom: 0px !important;
        margin-left: 10px !important;
    }
    .imgChecador {
        /* float: left;
        width: 38px;
        height: 38px; */
    }
    .divTitulo {
        margin-bottom: 60px;
    }
    .divTitulo2 {
        margin-bottom: 60px;
    }

    #wrapper #dialog {
        border: solid 2px #F6F6F6;
        margin: 10px auto;
        padding: 50px 50px;
        display: inline-block;
        /* box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1); */
        background-color: #ffffff;
        overflow: hidden;
        position: relative;
        min-width: 720px;
        max-height: 900px;
        border-radius: 15px;
    }

    .layout {
       /*  margin-top: -7% !important; */
    }
    .webcam {
        max-width: 500px;
        max-height: 350px;
        position: absolute
    }
    #dialog {
        margin-top: 10px !important;
    }
    .modal-button {
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 13px !important;
        line-height: 18px !important;
        background-color: #1E2245 !important;
        border-radius: 30px !important;
        color: white !important;
        width: 110px !important;
        height: 40px !important;
        outline: none;
        text-transform: none !important;
        margin-left: 3%;
        transition: all .2s;
    }
    .modal-button.save {
        background: transparent !important;
        box-shadow: none;
        color: #007eff;
        margin-bottom: 20px;
        margin-right: 20px;
        text-transform: none !important;
    }
    .position-relative {
        position: relative;
    }
    .title-otp{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: rgba(0, 0, 0, 0.87);
    }
    .titulo-opciones{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: #1E2245;
        display: flex;
        justify-content: center;
        
    }  

    .titulo-opciones h3{
        width: 500px;
        align-self: center;
    }

    .opciones {
        height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .btn-opcion-web{
        background: #ffffff !important;
        border: 1px solid #828282 !important;
        border-radius: 8px !important;

        height: 40px !important;
        width: 500px;
        align-self: center;
        display: block !important;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        text-transform: none;
        color: #828282;
        box-shadow: none;

    }

    .btn-opcion-web:hover{
        border: 2px solid rgba(30, 34, 69, 0.6);
        color: #1E2245;
        background: #ffffff !important;
    }

    .btn-opcion-codigo{
        background: #ffffff !important;
        border: 1px solid #828282 !important;
        border-radius: 8px !important;

        height: 40px !important;
        width: 500px;
        align-self: center;
        display: block !important;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        text-transform: none;
        color: #828282;
        box-shadow: none;
    }

    .btn-opcion-codigo:hover {
        border: 2px solid rgba(30, 34, 69, 0.6);
        color: #1E2245;
        background: #ffffff !important;
    }

    .btn-regresar {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: rgba(0, 0, 0, 0.7);
        margin-top: 8px !important;
        margin-bottom: 0px !important;
        margin-left: 10px !important;
    }

    .verificar-codigo {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .btn-verificar {
        background: #1E2245 !important;
        border: 1px solid #1E2245 !important;
        border-radius: 80px !important;

        height: 40px !important;
        width: 250px;
        align-self: center;
        display: block !important;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        text-transform: none;
        color: #ffffff;
        box-shadow: none;
        margin-top: 75px;
    }



</style>
